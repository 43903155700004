import React, { useState } from 'react';
import { Tabs, Space, Text, ActionIcon, useMantineTheme, Card, Stack, Flex } from '@mantine/core';
import { IoIosArrowBack } from 'react-icons/io';
import {  useNavigate } from 'react-router-dom';
import logo from '../../assets/logo.svg';
import LoginForm from './loginForm';
import RegisterForm from './registerForm';
import { useTranslation } from 'react-i18next';

const Login = ({ type }) => {
 const navigate = useNavigate();
 const { colorScheme } = useMantineTheme();
 const [value, setValue] = useState('login');
 const { t } = useTranslation();

 const handleChange = (value) => {
 setValue(value);
 };

 return (
 <Flex mih={'100vh'} justify='center' direction={'column'}>
 <div className={colorScheme === 'light' ? 'header__background_light' : 'header__background_dark'}></div>
 <Card>
 <ActionIcon variant='filled' color='red' size={50} onClick={() => navigate('/')} className='back'>
 <IoIosArrowBack size={50} />
 </ActionIcon>
 <Stack align='center'>
 <img src={logo} className='App-logo' alt='logo' width='340px' />
 <Stack align='center'>
 <Text c='dimmed'>{type === 'poster' ? t('purchase') : t('dmisson')}</Text>
 </Stack>
 <Space size='lg' />
 </Stack>
 <Tabs color='red' radius='md' value={value} onTabChange={handleChange}>
 <Tabs.List grow style={{ height: 70 }}>
 <Tabs.Tab value='login'>{t('login')}</Tabs.Tab>
 <Tabs.Tab value='register'>{t('register')}</Tabs.Tab>
 </Tabs.List>
 <Tabs.Panel value='login'>
 <LoginForm userType={type} />
 </Tabs.Panel>
 <Tabs.Panel value='register'>
 <RegisterForm userType={type} />
 </Tabs.Panel>
 </Tabs>
 </Card>
 </Flex>
 );
};

export default Login;
