import React, { useState, useEffect } from 'react'
import { Button, TextInput, Box, Stack } from '@mantine/core'

import axios from 'axios'

import { IoIosArrowBack } from 'react-icons/io'

import { useNavigate, useParams } from 'react-router-dom'

import logo from '../../assets/logo.svg'

import { useFormik } from 'formik'
import * as yup from 'yup'

const apiServer = process.env.REACT_APP_API_URL

const ResetPassword = ({ token }) => {
	let params = useParams()

	const [submit, setSubmit] = useState(false)

	const navigate = useNavigate()

	const [valid, setValid] = useState('loading')

	useEffect(() => {
		async function getToken() {
			await axios
				.get(apiServer + '/validate-token/' + params.token)
				.then((response) => {
					setValid('true')
				})
				.catch((error) => setValid('false'))
		}

		getToken()
	}, [params])

	const validationSchema = yup.object({
		password: yup
			.string('Enter a email')
			.required('Password is required')
			.matches(
				/^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
				'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number & one special case Character'
			),

		passwordConfirmation: yup.string().oneOf([yup.ref('password'), null], 'Passwords must match'),
	})

	const setPassword = (obj) => {
		setSubmit(true)

		axios
			.post(apiServer + '/setpassword', obj)
			.then((response) => {
				setSubmit(true)
			})
			.catch((error) => console.log(error))
	}

	const initVals = { password: '', passwordConfirmation: '', token: token }

	const formik = useFormik({
		enableReinitialize: true,
		validationSchema: validationSchema,
		initialValues: initVals,

		onSubmit: (values) => {
			values.token = params.token

			console.log(values)

			setPassword(values)
		},
	})

	if (submit) {
		return (
			<header className='App-header'>
				<div onClick={(e) => navigate(-1)} className='back'>
					{' '}
					<IoIosArrowBack />
				</div>

				<img src={logo} className='App-logo' alt='logo' width='340px' />

				<b>Reset Password?</b>

				<Box>
					<Stack >
						<br />
						<p className='medium'>Successfully Updated Password.</p>

						<Button onClick={(e) => navigate('/')} color='primary' size='large' variant='contained' type='submit' spacing='10'>
							Login
						</Button>
					</Stack>
				</Box>
			</header>
		)
	}

	if (valid === 'true')
		return (
			<header className='App-header'>
				<img src={logo} className='App-logo' alt='logo' width='340px' />

				<b>Reset Password</b>

				<Box>
					<Stack direction='column' justifyContent='center' alignItems='stretch' maxWidth='640px' padding='20px' spacing={2}>
						<br />
						<small>Password Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character</small>

						<br />

						<TextInput
							id='password'
							label='New Password'
							value={formik.values.password}
							onChange={formik.handleChange}
							error={formik.touched.password && Boolean(formik.errors.password)}
							helperText={formik.touched.password && formik.errors.password}
							type='password'
						/>

						<TextInput
							id='passwordConfirmation'
							label='Password Again'
							value={formik.values.passwordConfirmation}
							onChange={formik.handleChange}
							error={formik.touched.passwordConfirmation && Boolean(formik.errors.passwordConfirmation)}
							helperText={formik.touched.passwordConfirmation && formik.errors.passwordConfirmation}
							type='password'
						/>

						<br />

						<Button onClick={formik.handleSubmit} color='primary' size='large' variant='contained' type='submit' spacing='10'>
							Save new password
						</Button>
					</Stack>
				</Box>
			</header>
		)

	if (valid === 'false')
		return (
			<header className='App-header'>
				<div onClick={(e) => navigate(-1)} className='back'>
					{' '}
					<IoIosArrowBack />
				</div>

				<img src={logo} className='App-logo' alt='logo' width='340px' />

				<b>Reset Password</b>

				<Box>
					<Stack direction='column' justifyContent='center' alignItems='stretch' maxWidth='640px' padding='20px' spacing={2}>
						<br />
						<p className='medium'>Change password link is expired</p>

						<Button onClick={(e) => navigate('/')} color='primary' size='large' variant='contained' type='submit' spacing='10'>
							Login
						</Button>
					</Stack>
				</Box>
			</header>
		)

	if (valid === 'loading') return <div></div>
}
export default ResetPassword
