import React from 'react'
import { Button } from '@mantine/core'
import { useTranslation } from 'react-i18next'

export default  function SuccessfullyBooked({ mission, closeFunc }) {
  const { t } = useTranslation()
  return (
    <div style={{ textAlign: 'center' }}>
      <h1>{t('congrats')}</h1>
      {t('bookinf3')} <b># {mission.shortReference}</b>. {t('bookinf4')}
      <h3>{t('bookinf5')}</h3>
      <Button fullWidth onClick={closeFunc}>
        {t('close')}
      </Button>
    </div>
  )
}
