import React from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Stack } from '@mantine/core'

const ConfirmBooking = ({ book, closeFunc }) => {
	const { t } = useTranslation()
	return (
		<div style={{ textAlign: 'center' }}>
			<div style={{ padding: '50px' }}>
				<div>
					<b>{t('bookinf1')}</b> <span style={{ color: 'GrayText' }}>{t('bookinf2')}</span>
				</div>
			</div>
			<Stack gap='10px' direction='row'>
				<Button fullWidth variant='light' onClick={closeFunc}>
					{t('cancel')}
				</Button>
				<Button fullWidth onClick={book}>
					{t('book')}
				</Button>
			</Stack>
		</div>
	)
}

export default ConfirmBooking
