import { Badge, CloseButton } from "@mantine/core";

function DeletableChip({ label, onRemove,onClick }) {
  return (
    <Badge variant="light" radius="xl" rightSection={<CloseButton size="xs" onClick={onRemove} />} onClick={onClick}>
      {label}
    </Badge>
  );
}

export default DeletableChip;