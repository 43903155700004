import React from 'react';
import { Card, Group, Stack, Text, ThemeIcon, SimpleGrid, RingProgress, useMantineTheme, Divider, Space } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import { MdDeliveryDining, MdAttachMoney, MdCalendarMonth } from 'react-icons/md';

const BookerStats = ({ stats }) => {
  const { t } = useTranslation();
  const theme = useMantineTheme();
  
  // If no stats are provided, show placeholder or loading state
  if (!stats) return (
    <Card withBorder p="md" radius="md" shadow="sm">
      <Text weight={500} size="lg" mb="md">{t('stats_loading')}</Text>
    </Card>
  );

  const { period, totalMissions, totalBookerNet, totalBookerSees, currency } = stats;
  
  // Calculate completion percentage (example calculation)
  const completionPercentage = totalMissions > 0 ? Math.min(100, Math.round((totalMissions / 20) * 100)) : 0;

  return (
    <>
   
    <Card withBorder p="lg" radius="md" shadow="sm">
    <Group position="right" spacing="xs">
    <Text size="xs" color="dimmed">{t('period')}</Text>
    <Text size="xs" weight={500}>{period?.label || '-'}</Text>
    </Group>
    <Space h="md" />
      
      
      <SimpleGrid cols={2}>
        <Group>
          <RingProgress
            size={80}
            roundCaps
            thickness={8}
            sections={[{ value: completionPercentage, color: theme.primaryColor }]}
            label={
              <Text color="blue" weight={700} align="center" size="lg">
                {totalMissions}
              </Text>
            }
          />
          <div>
            <Text size="xs" color="dimmed">{t('total_missions')}</Text>
            <Text weight={500}>{t('completed')}</Text>
          </div>
        </Group>
        
        <Card p="xs" withBorder shadow="sm" radius="md">
          <Group noWrap spacing="xs">
            <ThemeIcon size="md" radius="md" color="green" variant="light">
              <MdAttachMoney size={16} />
            </ThemeIcon>
            <div style={{ flex: 1, textAlign: 'right' }}>
              <Text size="xs" color="dimmed">{t('your_earnings')}</Text>
              <Text weight={700} size="md">{totalBookerNet} {currency}</Text>
            </div>
          </Group>
        </Card>
    
      </SimpleGrid>
    </Card>
    </>
  );
};

export default BookerStats;
