import React, { useContext, useState } from 'react'
  
import { UserContext } from '../../_context'
import axios from 'axios'

 
import { useClipboard } from '@mantine/hooks'
 
import SuccessfullyBooked from './helpers/SuccessfullyBooked'
import AllMissionsBooked from './helpers/AllMissionsBooked'
import ConfirmBooking from './helpers/ConfirmBooking'
import MissionDetails from './helpers/MissionDetails'  // <-- new import

function Mission({ mission, closeFunc, setShow }) {
	// ...existing imports and context...
	const [err, setErr] = useState({})
	const clipboard = useClipboard()
	const apiServer = process.env.REACT_APP_API_URL
	const [user] = useContext(UserContext)
	const config = { headers: { Authorization: `Bearer ${user.token}` } }
 
	const [activeStep, setActiveStep] = useState(mission.status === 'booked' ? 2 : 0)

	const book = () => {
		setActiveStep(2)
		axios
			.post(`${apiServer}/booker/mission/book`, { id: mission._id }, config)
			.catch(() => setActiveStep(3))
	}

	// Removed inline missionDetailsNoCommentsOrPosterBox function

	const renderStep = () => {
		switch (activeStep) { 
			case 1:
				return <ConfirmBooking book={book} closeFunc={closeFunc} />
			case 2:
				return <SuccessfullyBooked mission={mission} closeFunc={closeFunc} />
			case 3:
				return <AllMissionsBooked err={err} onClose={setShow} />
			default:
				return <MissionDetails mission={mission} clipboard={clipboard} setStep={setActiveStep} />
		}
	}

	return ( <> {renderStep()} </> )
}

export default Mission
