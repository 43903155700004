import React, { useState } from 'react'
import './global.css'
import { GlobalProvider } from './_context' 
import { MantineProvider, ColorSchemeProvider } from '@mantine/core'
import { Notifications } from '@mantine/notifications'
import { useCookies } from 'react-cookie'
import { SpotlightProvider } from '@mantine/spotlight'
import { useColorScheme } from '@mantine/hooks'

import AppRoutes from './components/routes'
import { IconHome, IconDashboard, IconFileText, IconSearch } from '@tabler/icons-react'
import { ModalsProvider } from '@mantine/modals'
 
import TagManager from 'react-gtm-module'

const tagManagerArgs = {
   gtmId: 'G-FP7361B1NY'
}


const actions = [
   {
      title: 'Home',
      description: 'Get to home page',
      onTrigger: () => {
         window.location.href = '/'
      },
      icon: <IconHome size='1.2rem' />
   },
   {
      title: 'Dashboard',
      description: 'Get full information about current system status',
      onTrigger: () => {
         window.location.href = '/logged/poster/'
      },
      icon: <IconDashboard size='1.2rem' />
   },
   {
      title: 'Documentation',
      description: 'Visit documentation to lean more about all features',
      onTrigger: () => {
         window.location.href = 'https://pedallo.com/gtc/'
      },
      icon: <IconFileText size='1.2rem' />
   }
]

 
TagManager.initialize(tagManagerArgs)

function App() {
   const [cookies, setCookie] = useCookies()

   const oscs = useColorScheme()

   const preferredColorScheme = cookies?.['mantine-color-scheme'] || oscs // oparating system color scheme

   const [colorScheme, setColorScheme] = useState(preferredColorScheme)

   const toggleColorScheme = () => {
      const ColorScheme = colorScheme === 'dark' ? 'light' : 'dark'
      const nextColorScheme = ColorScheme || (ColorScheme === 'dark' ? 'light' : 'dark')
      setCookie('mantine-color-scheme', nextColorScheme, { maxAge: 60 * 60 * 24 * 30 })
      setColorScheme(nextColorScheme)
   }

   return (
      <>
         <ColorSchemeProvider colorScheme={colorScheme} toggleColorScheme={toggleColorScheme}>
            <ModalsProvider>
               <MantineProvider
                  theme={{
                     primaryColor: 'red',
                     globalStyles: (theme) => ({
                        body: {
                           backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[8] : 'linen;',
                           color: theme.colorScheme === 'dark' ? 'white' : theme.colors.dark[5]
                        }
                     }),

                     defaultRadius: 'md',
                     defaultGradient: { from: 'pink', to: 'red' },

                     fontFamily: 'suisse intl, sans-serif',
                     colorScheme: colorScheme,

                     headings: {},
                     components: {
                        Button: {
                           defaultProps: { color: 'red', tt: 'uppercase' },
                           styles: {
                              fontWeight: 700,
                              fontSize: '1rem'
                           }
                        },

                        title: {
                           styles: {
                              fontWeight: 700
                           }
                        }
                     },
                     cursorType: 'pointer'
                  }}>
                  <GlobalProvider>
                  
                        <SpotlightProvider actions={actions} searchIcon={<IconSearch size='1.2rem' />} searchPlaceholder='Search...' shortcut='mod + s' nothingFoundMessage='Nothing found...'>
                           <div>
                              <Notifications position='top-left' color='red' />
                              <AppRoutes />
                           </div>
                        </SpotlightProvider>
                    
                  </GlobalProvider>
               </MantineProvider>
            </ModalsProvider>
         </ColorSchemeProvider>
      </>
   )
}

export default App
