import React from 'react'
import moment from 'moment'

import { BsFillTelephoneForwardFill } from 'react-icons/bs'
import { ActionIcon, Anchor, Card, Group, Stack, Text } from '@mantine/core'

function bookerbox({ mission }) {
	const namer = mission.bookerDetails.name

	const name = namer.split(' ')[0]

	return (
		<Card withBorder>
			<Group position='apart'>
				<Stack spacing={1}>
					<Text size='xs' weight={700} mt={1}>
						Booked @ {moment(mission.bookerDetails.bookedAt).calendar()}
					</Text>
					<Text size={'xs'} c='dimmed'>
						By {name} <b>{'tel:' + mission.bookerDetails.phone}</b>
					</Text>
				</Stack>

				<Group position='apart'>
					<Anchor href={'Tel:' + mission.bookerDetails.phone}>
						<ActionIcon variant='filled' size='xl'>
							<BsFillTelephoneForwardFill />
						</ActionIcon>
					</Anchor>
				</Group>
			</Group>
		</Card>
	)
}

export default bookerbox
