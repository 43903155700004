import React from 'react'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { Button, Card, Stack, Text, Group, SimpleGrid, Tooltip, Badge } from '@mantine/core'
import AttachedRequirements from '../../tools/AttachedRequirements'
import Map from '../../tools/mapc'
import DeliveryModeButton from './DeliveryModeButton'

const MissionDetails = ({ mission, clipboard,setStep }) => {
	const { t } = useTranslation()
	return (
		<Stack>
			<Group position='apart' align='top' spacing={20}>
				<Stack>
					<Text weight={700} size='sm'>
						{t('pickup_time')} {moment(mission.time).calendar()}
					</Text>
					<Group>
						{mission.immediate && <div className='immediate'>immediate</div>}
						{mission.hourly && (
							<Badge size='xs' color='yellow'>
								{t('hourly')} +{mission.hourlyRate} hrs.
							</Badge>
						)}
					</Group>
					<Group position='apart' grow>
						<AttachedRequirements mission={mission} />
					</Group>
				</Stack>
				<Stack spacing='xs'>
					<Text size='xl' weight={900}>
						Net
					</Text>
					<Text size='xl' weight={900}>
						{mission.pricing.price.bookerNet} {mission.pricing.currency.name}
					</Text>
				</Stack>
				<Stack spacing={10}>
					<Group spacing='lg'>
						<Stack>
							<Tooltip width={80} multiline label='Copied to clipboard!' transitionProps={{ duration: 300, transition: 'slide-down' }} opened={clipboard.copied}>
								<Button radius={5} size='xs' variant='default' uppercase onClick={() => clipboard.copy(mission.shortReference)}>
									{mission.shortReference}
								</Button>
							</Tooltip>
							<DeliveryModeButton mode={mission.deliveryMode} header={t('b_mode')} />
						</Stack>
					</Group>
				</Stack>
			</Group>

			<SimpleGrid cols={2} spacing={20} breakpoints={[{ maxWidth: 'md', cols: 1 }]}>
				<Stack>
					<Button onClick={() => {setStep(1)}}>{t('book')}</Button>
					<Map mission={mission} width='100' height='100' />
				</Stack>
				<Stack>
					<Card withBorder>
						<Group>
							<Text weight={700}>{t('from')}.</Text>
							<Text size='xs' c='dimmed'>
								{mission.from.place_name}
							</Text>
						</Group>
					</Card>
					<Card withBorder>
						<Group>
							<Text weight={700}>{t('to')}.</Text>
							<Text size='xs' c='dimmed'>
								{mission.createType === 'qr' ? 'Zone ' + mission.zone : mission.to.place_name}
								{mission.hourly && (
									<Badge size='xs' color='yellow'>
										{t('hourly')} +{mission.hourlyRate} hrs.
									</Badge>
								)}
							</Text>
						</Group>
					</Card>
				</Stack>
			</SimpleGrid>
		</Stack>
	)
}

export default MissionDetails
