import React from 'react'
import { HiOutlineEmojiSad } from 'react-icons/hi'
import { useTranslation } from 'react-i18next'
import { Button } from '@mantine/core'

const AllMissionsBooked = ({ err, onClose }) => {
	const { t } = useTranslation()
	return (
		<div style={{ textAlign: 'center' }}>
			<div style={{ margin: '30px' }}>
				<HiOutlineEmojiSad style={{ fontSize: '80px', color: 'firebrick' }} />
				<h1>Sorry!</h1>
				<div style={{ color: 'GrayText' }}>{err}</div>
			</div>
			<Button fullWidth variant='outlined' onClick={onClose}>
				{t('close')}
			</Button>
		</div>
	)
}

export default AllMissionsBooked
