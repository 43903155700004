import React from 'react';
import { Group, Button,Text } from '@mantine/core';
  
import { MdPedalBike } from 'react-icons/md';
import { AiOutlineCar } from 'react-icons/ai';

const All = () => 'ALL';

const DeliveryModeIcon = ({ mode }) => {  return iconMapping[mode] || null;  };

const iconMapping = {
    all: <All />,
    bicycle: <MdPedalBike />,
    foot: <MdPedalBike />,
    car: <AiOutlineCar />
 };

 
const DeliveryModeButton = ({ mode,header = "Delivery Mode:)" }) => {
   if (!mode) return null;
   return (
    
      <Group position='apart' align='center' grow spacing={'lg'}>
         <Text size={'xs'} weight={700} c='dimmed'> {header}</Text>
         <Button variant="outline">
            <DeliveryModeIcon mode={mode} />
         </Button>
      </Group>
   );
};

export default DeliveryModeButton;
