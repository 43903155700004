import React, { useState, useRef, useCallback } from 'react'

import MapGL, { Marker } from 'react-map-gl'
import Geocoder from 'react-map-gl-geocoder'
import axios from 'axios'

import { ActionIcon } from '@mantine/core'
import { FaLocationArrow } from 'react-icons/fa'

import Pin from './Pin.js'

import '../tools/mapbox-gl-geocoder.css'
import 'mapbox-gl/dist/mapbox-gl.css'

// Please be a decent human and don't abuse my Mapbox API token.
// If you fork this sandbox, replace my API token with your own.
// Ways to set Mapbox token: https://uber.github.io/react-map-gl/#/Documentation/getting-started/about-mapbox-tokens

// set focus on the input on start

const MAPBOX_TOKEN = process.env.REACT_APP_MAPBOX_API_KEY

const Map = ({ func, centr, country }) => {
   const [address, setAddress] = useState('')

   const [locatorLoading, setLocatorLoading] = useState(false)

   const getCurrentLocation = (e) => {
      setLocatorLoading(true)
      navigator.geolocation.getCurrentPosition(function (position) {
         handleViewportChange({ latitude: position.coords.latitude, longitude: position.coords.longitude, zoom: 12 })
         axios.get(`https://api.mapbox.com/geocoding/v5/mapbox.places/${position.coords.longitude},${position.coords.latitude}.json?access_token=${MAPBOX_TOKEN}`).then(({ data }) => {
            func({ result: data.features[0] })
            setAddress(data.features[0].place_name)
            setLocatorLoading(false)
            setMarker({
               latitude: position.coords.latitude,
               longitude: position.coords.longitude
            })
         })
      })
   }

   const [viewport, setViewport] = useState(centr)

   const mapRef = useRef()
   const geopRef = useRef()
   const geocoderContainerRef = useRef()

   const [marker, setMarker] = useState({
      latitude: centr.latitude,
      longitude: centr.longitude
   })

   const handleViewportChange = useCallback((newViewport) => {
      setViewport(newViewport)
   }, [])

   const handleResult = (result) => {
      if (result.result.place_name) setAddress(result.result.place_name)
      func(result)

      handleViewportChange({
         latitude: result.result.center[1],
         longitude: result.result.center[0],
         zoom: 12
      })

      setMarker({
         latitude: result.result.center[1],
         longitude: result.result.center[0]
      })
   }

   const onMarkerDrag = useCallback((event) => {
      setMarker({
         longitude: event.lngLat[0],
         latitude: event.lngLat[1]
      })
   }, [])

   const onMarkerDragEnd = useCallback(
      (event) => {
         setLocatorLoading(true)

         setMarker({
            longitude: event.lngLat[0],
            latitude: event.lngLat[1]
         })

         axios.get(`https://api.mapbox.com/geocoding/v5/mapbox.places/${event.lngLat[0]},${event.lngLat[1]}.json?access_token=${MAPBOX_TOKEN}`).then(({ data }) => {
            func({ result: data.features[0] })
            setAddress(data.features[0]?.place_name)
            setLocatorLoading(false)
         })
      },
      [func]
   )

   return (
      <div style={{ height: '400px', width: '100%' }}>
         <div ref={geocoderContainerRef} style={{ marginBottom: '20px' }} />

         <MapGL ref={mapRef} {...viewport} width='100%' height='100%' onInteractionStateChange={(e) => {}} onViewportChange={handleViewportChange} mapboxApiAccessToken={MAPBOX_TOKEN}>
            {locatorLoading ? <div style={{ margin: '10px' }}>Locating...</div> : <div></div>}

            <Geocoder inputValue={address} ref={geopRef} reverseGeocode={true} types='address' limit={2} mapRef={mapRef} marker containerRef={geocoderContainerRef} onViewportChange={handleViewportChange} mapboxApiAccessToken={MAPBOX_TOKEN} onResult={handleResult} countries={country ? country : 'ch'} position='top-left' placeholder='Address' />

            <Marker longitude={marker.longitude} latitude={marker.latitude} con anchor='bottom' draggable onDrag={onMarkerDrag} onDragEnd={onMarkerDragEnd}>
               <Pin size={20} />
            </Marker>
         </MapGL>

         <ActionIcon variant='filled' onClick={getCurrentLocation} style={{ position: 'absolute', marginTop: -70 ,marginLeft:10 }} color='black'>
            <FaLocationArrow />
         </ActionIcon>
      </div>
   )
}

export default Map
